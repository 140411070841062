import { Box, styled } from "@mui/material";
import { Link } from "react-router-dom";
import ColorsWeeklyLogo from "../assets/images/Logo.png";

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  cursor: "pointer !important",
}));

const Logo = () => {
  return (
    <StyledLink to="/">
      <Box
        component={"img"}
        src={ColorsWeeklyLogo}
        width={250}
        sx={{
          cursor: "pointer !important",
        }}
      />
    </StyledLink>
  );
};

export default Logo;
